<template>
  <b-card class="rounded-0 bg-transparent border-0 venue-item" no-body>
    <b-link
      :to="{ path: 'venue/' + venue.id }"
      append
      :disabled="$route.params.id != null"
      class="position-absolute w-100 h-100"
    >
    </b-link>
    <b-card-body v-if="venue.activeMenus.length" class="px-3 py-0">
      <div
        v-if="!hideHeader && displayMenu"
        class="bg-white shadow-lg py-3 inner border border-bottom-0"
      >
        <mdj-menu :menu="venue.activeMenus[0]" :search="search" />
      </div>
      <div
        v-else-if="!hideHeader"
        class="bg-primary inner text-white text-uppercase text-center py-2"
      >
        Menu du jour disponible dans ce restaurant
      </div>
    </b-card-body>
    <b-card-body class="shadow-lg bg-white p-0 pt-2 pb-0 border">
      <b-row no-gutters>
        <b-col cols="12" class="px-3 p-2 d-flex justify-content-between">
          <h2 class="h3 text-uppercase text-primary items-top mb-0">
            {{ venue.name }}
            <!-- <small class="d-block" v-if="venue.badge">Abonné</small> -->
          </h2>
          <div class="btn-wrapper position-absolute">
            <div class="d-flex justify-content-between">
              <like-btn :venue="venue" class="ml-0" />
              <bookmark-btn :venue="venue" />
              <share-btn :venue="venue" class="flat text-primary" />
            </div>
            <span class="d-block d-lg-none text-right distance">
              {{ venue.distance / 1000 }}km
            </span>
          </div>
        </b-col>
        <b-col cols="9" lg="10" class="pl-3">
          <div class="p-2 d-flex flex-column h-100">
            <small class="display font-weight-normal d-block location">
              <font-awesome-icon icon="map-marker-alt" class="mr-2" />
              {{ venue.address1 }} -
              <template v-if="venue.address2">
                {{ venue.address2 }} -
              </template>
              {{ venue.zipcode }}
              {{ venue.city }}
              <span class="d-none d-lg-block">
                {{ venue.distance / 1000 }}km
              </span>
            </small>
            <div
              v-if="venue.open"
              class="
                align-self-start
                text-primary text-uppercase
                font-weight-demi
                my-2
              "
            >
              OUVERT AUJOURD'HUI
            </div>
            <p v-html="venueDescription"></p>
            <div class="mt-auto d-flex flex-column mr-3">
              <div class="d-flex">
                <call-btn
                  :venue="venue"
                  :icon="venue.onlineReservation"
                  class="btn-block-sm"
                />
                <book-btn
                  v-if="venue.onlineReservation"
                  :venue="venue"
                  :icon="false"
                  class="btn-block-sm ml-3"
                />
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="3" lg="2" class="px-2 d-flex flex-column py-4">
          <div class="d-flex justify-content-end mb-2 flex-column"></div>
          <div class="text-center my-auto position-relative">
            <div
              v-lazy:background-image="venue.picture"
              class="cover rounded"
            ></div>
            <b-img-lazy
              v-if="venue.badge"
              :alt="venue.name"
              :src="venue.badge"
              class="venue-badge position-absolute"
            />
          </div>
        </b-col>

        <b-col v-if="venue.shopEnabled" cols="12">
          <b-row class="no-gutters actions-row mt-3">
            <b-col v-if="venue.shopEnabled">
              <b-btn
                :href="venue.shopUrl"
                class="
                  w-100
                  rounded-0
                  d-flex
                  justify-content-between
                  text-primary
                  align-items-center
                "
                variant="light"
              >
                <font-awesome-icon
                  icon="shopping-cart"
                  class="text-primary"
                ></font-awesome-icon>
                Commander en ligne
                <div></div>
              </b-btn>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-body
      v-if="venue.activeDeals.length"
      v-advertiser="{ campaignId: advertiserDay.campaignId, view: view }"
      class="px-3 py-0"
    >
      <div
        class="
          inner
          border
          bg-white
          shadow-l
          border border-top-0
          d-flex
          flex-column flex-md-row
          align-items-md-center
        "
        style="box-sizing: border-box"
      >
        <div
          class="logo position-absolute d-flex align-items-center mw-100 p-2"
        >
          <b-img-lazy
            :src="advertiserDay.logo.original"
            style="border-bottom-left-radius"
          />
        </div>
        <b-col
          cols="9"
          md="7"
          offset-md="1"
          offset="2"
          class="px-2 pl-3 pt-2 pt-md-0"
        >
          {{ advertiserDay.listVenueText }}
        </b-col>
        <b-col cols="7" md="4" offset="2" offset-md="0" class="px-2 text-right">
          <active-deal-btn
            :venue="venue"
            class="flex-fill ml-1 btn-block-sm my-2"
            redirect
          />
        </b-col>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import MDJMenu from "@/components/Menu"
import informations from "@/mixins/informations"
import {
  BookBtn,
  BookmarkBtn,
  LikeBtn,
  ShareBtn,
  CallBtn
} from "@/components/buttons"

export default {
  components: {
    BookBtn,
    BookmarkBtn,
    LikeBtn,
    ShareBtn,
    CallBtn,
    "mdj-menu": MDJMenu
  },
  mixins: [informations],
  props: {
    venue: {
      type: Object,
      required: true
    },
    displayMenu: {
      type: Boolean,
      required: false,
      default: true
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    advertiserDay: {
      type: Object,
      required: true
    },
    view: {
      type: String,
      default: "list"
    },
    search: {
      type: String,
      default: ""
    }
  },
  computed: {
    venueDescription: function() {
      if (this.venue.description.length > 230) {
        return this.venue.description.substr(0, 230) + "..."
      }
      return this.venue.description
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
a:hover {
  text-decoration: none;
}
a.position-absolute {
  z-index: 10;
}
.btn {
  z-index: 15;
  position: relative;
}
</style>
